import React from 'react';
import { graphql } from 'gatsby';
import 'moment/locale/es';
import 'moment/locale/pt';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PagerComponent from '../components/PagerComponent';

export const query = graphql`
  query Donaciones($id: String) {
    prismic {
      allDonacioness(id: $id) {
        edges {
          node {
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`;

const Donations = ({ data, location }) => {
  const page = data.prismic.allDonacioness.edges[0];

  if (!page || !page.node) {
    return null;
  }

  const {
    _meta: { lang },
  } = page.node;

  return (
    <Layout lang={lang} location={location}>
      <SEO title="Donations" />
      <PagerComponent initialSection="donations" lang={lang} />
    </Layout>
  );
};

export default Donations;
